.MobilePreviewContainer {
  position: relative;
  width: 280px;
  height: 552px;
  background-size: cover;
  overflow: hidden;
  padding: 105px 24px 78px 32px;

  .MobilePreviewBody {
    height: 100%;
    overflow: hidden;
    padding-right: 8px;
    cursor: grab;
    user-select: none;
  }
}